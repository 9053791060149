<template>
  <b-row align-h="center">
    <b-col sm="12" md="10" lg="8" xl="6">
      <b-card class="mb-2 form-card">
        <template #header class="card-header">
          <h4 class="my-2">Erreur 404 — Page introuvable</h4>
        </template>
        <b-card-body>
           <router-link :to="{ name: 'login' }">
             Revenir à l'accueil
           </router-link>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Error404',
}
</script>